.video-card {
    margin-bottom: 30px;
    a {
        position: relative;
        display: block;
        transition: color 0.35s ease;

        &:hover {
            text-decoration: none;
            color: $brand-danger;

            .overlay {
                height: 100%;

                i.fa {
                    opacity: 0.9;
                }
            }
        }

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: rgba(0, 0, 0, 0.6);

            transition: width 0.35s ease, height 0.35s ease;

            i.fa {
                font-size: 24px;
                color: #fff;
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -10px 0 0 -10px;
                height: 100%;
                vertical-align: middle;
                opacity: 0;

                transition: opacity 0.35s ease;
            }
        }
    }
    .caption {
        margin-top: 10px;
    }
}