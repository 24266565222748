@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=latin,vietnamese';

$font-family-sans-serif:  "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

$brand-primary:         #2c3e50 !default;
$brand-success:         #2ecc71 !default;
$brand-info:            #19b5fe !default;
$brand-warning:         #f27935 !default;
$brand-danger:          #f64747 !default;

$text-color:            $brand-primary !default;

$border-radius-base:        0 !default;
$border-radius-large:       0 !default;
$border-radius-small:       0 !default;



// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;




// Basics of a navbar
$navbar-height:                    50px !default;
$navbar-margin-bottom:             $line-height-computed !default;
$navbar-border-radius:             $border-radius-base !default;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height:       340px !default;

$navbar-default-color:             $brand-primary !default;
$navbar-default-bg:                #fff !default;
$navbar-default-border:            none !default;

// Navbar links
$navbar-default-link-color:                $brand-primary !default;
$navbar-default-link-hover-color:          #fff !default;
$navbar-default-link-hover-bg:             $brand-danger !default;
$navbar-default-link-active-color:         #fff !default;
$navbar-default-link-active-bg:            $brand-danger !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color !default;
$navbar-default-brand-hover-color:         $brand-danger !default;
$navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd !default;
$navbar-default-toggle-icon-bar-bg:        #888 !default;
$navbar-default-toggle-border-color:       #ddd !default;

//== Pills
// $nav-pills-border-radius:                   $border-radius-base !default;
// $nav-pills-active-link-hover-bg:            $brand-danger !default;
// $nav-pills-active-link-hover-color:         #fff !default;

//== Pagination
$pagination-color:                     $brand-primary !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    none !default;

$pagination-hover-color:               #fff !default;
$pagination-hover-bg:                  $brand-danger !default;
$pagination-hover-border:              none !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $brand-primary !default;

$pagination-disabled-color:            #ddd !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           none !default;

@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import './components/navbar';
@import './components/header';
@import './components/footer';
@import './components/pagination';
@import './components/video-card';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';